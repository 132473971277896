.user {
  /* position: relative; */
  text-align: center;
}
.user__avatar {
  border-radius: 50%;
}
.user__infos {
  text-align: left;
  padding:var(--spacing-3) 0 var(--spacing-1) 0;
}
.user__info {
  display: flex;
}
.user__info svg {
  color:var(--icon-color);
  margin-right: 1rem;
}
.dark .user__info svg {
  color:var(--dark-icon-color);
}
