.grid__item,
.grid__item p {    
  margin-bottom: var(--spacing-1)
}
.grid__date {  
  font-weight: 500;
}
.grid__title {
  margin-bottom: 0;
}
.grid__location {
  font-weight: 500;
  color:var(--date-color);
}
.dark .grid__location {
  
  color: var(--dark-date-color);
}
.grid__mission {
  margin-bottom:var(--spacing-1);
}