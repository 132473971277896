.skills {
  margin: var(--spacing-3) 0;
}
.skill {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.interests {
  margin: var(--spacing-1);
  align-items: center;
}

.interests svg {
  margin: var(--spacing-1);
  align-items: center;
}
