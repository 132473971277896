@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500");

*,
::before,
::after {
  margin: 0;
  padding: 0%;
  box-sizing: border-box;
  outline: none;
}
:root {
  /*colors*/
  --body-bg-color: #FFFFFF;
  --sidebar-bg-color: #7acdfa;
  --text-bg-color: #333333;
  --title-color: #111111;
  --date-color: #1f94c2;
  --dark-body-bg-color: #242424;
  --dark-sidebar-bg-color: #171717;
  --dark-text-color: #d8d8d8;
  --dark-title-color: #ffffff;
  --dark-date-color: #e6ef71;

  --icon-color:var(--text-color);
  --circle-color: #AAA;
  --circle-color-plain: #555;
  --dark-icon-color: var(--dark-text-color);
  --dark-circle-color: rgb(108, 108, 108);
  --dark-circle-color-plain: rgb(238, 238, 238);

  /* bonus */

  /* bae5ff */

  /*fonts*/
  --body-font: "Poppins", "Sans-serif";
  --h1-font-size: 1.3rem;
  --h2-font-size: 1.1rem;
  --h3-font-size: 0.8rem;
  --h4-font-size: 1.1rem;

  /*helpers*/
  --spacing-1: .3rem;
  --spacing-2: .5rem;
  --spacing-3: .8rem;
  --spacing-4: .8rem;
  --spacing-5: .8rem;
}

body {
  font-family: var(--body-font);
  font-weight: 100;
  background-color: var(--bg-color);
  color: var(--text-color);  
}
.dark {
  color: var(--dark-text-color);
  background-color: var(--dark-body-bg-color);
}
h1,
h2,
h3 {
  margin-bottom: var(--spacing-1);
  font-weight: 400;
  color: var (--title-color);
}

.dark h1,
.dark h2,
.dark h3 {
  color: var(--dark-title-color);
}
h1 {
  font-size:var(--h1-font-size);
  text-transform: uppercase;
}
h2 {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-4);
  font-size: var(--h2-font-size);
  letter-spacing: 3px;
  text-transform: uppercase;
}

h3 {
  font-size: var(--h3-font-size);
  letter-spacing: 2px;
}

h4 {
  font-size: var(--h4-font-size);
  letter-spacing: 3px;
}

p {
  margin-bottom: var(--spacing-2);
}

ul {
  padding-left:1.4rem;
}

li {
  margin-bottom: var(--spacing-2);
}

a {
  text-decoration: none;
  color: var(--text-color);
}

.dark a {
  color: var(--dark-text-color);
}

button {
  background: none;
  border:none;
  outline: none;
  cursor: pointer;
}
button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.dark button {
  color: var(--dark-text-color);
}

/* Helpers */

.mb3 {
  margin-bottom: var(--spacing-3);
}

.mb5 {
  margin-bottom: var(--spacing-5);
}

/*grids */
.grid__row {
  margin: var(--spacing-3) 0;
}
@media screen and (min-width:768px) {
  .grid__container {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
  .grid__row {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: 0;
  }
}

/* layouts */

.sidebar {
  /* bonus
  width:100%;
  position: relative;
  */
  padding:1rem;
  background-color: var(--sidebar-bg-color);
}

/* bonus
.sidebar::before {
  position: absolute;
  top:0;
  left: 0;
  z-index: 0;
  content: '';
  display: block;
  width: 100%;
  height: 120px;
  background-color: #bae5ff;
  clip-path: polygon(0 0, 100% 1%, 100% 100%, 75% 86%, 25% 86%, 0 100%);
}
 */
.dark .sidebar {
  background-color: var(--dark-sidebar-bg-color);
}

.actions {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.main {
  padding:1.5rem;
}
/* icons*/
.circle {
  display: inline-block;
  width:20px; 
  height: 20px;
  margin-right: 2px;
  border-radius: 20px;
  background-color: var(--circle-color);
}
.circle-plain {
  background-color: var(--circle-color-plain);
}
.dark .circle {
  background-color: var(--dark-circle-color);
}
.dark .circle-plain {
  background-color: var(--dark-circle-color-plain);
}

/* Generate PDF */

#cv-print {
  width: 100% !important;
}
.cv-print .actions {
  display: none;
}

.cv-print ul {
  margin:0;
  padding:0;
}
.cv-print ul li {
  list-style-type:none;
}
.cv-print ul li::before {
  content: "•";
  display: inline-block;
  font-size: 100%;
  padding-right: 15px;
}
.cv-print .grid__container {
  font-size:8px !important;
  max-width: 210mm;
}
.cv-print .sidebar {
  height: 297mm;
}
.cv-print .user__infos svg {
  display: none !important;
}
.cv-print .circle {
  width:10px;
  height:10px;
}
.cv-print .grid__date {
  margin-top:2px;
}
